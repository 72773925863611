import port from '@config/port';
import { defineStore } from 'pinia';
import systemInfo from './systemInfo';
import { mergeReq } from '@utils/request';
import { USER_INFO } from '@config/constant';

export default defineStore('projectData', {
	state: (): {
		config: any;
		userInfo: any;
	} => ({
		config: {},
		userInfo: {},
	}),

	actions: {
		async fetchUserInfo(type: string = 'default'): Promise<any> {
			if (Object.keys(this.userInfo).length > 0 && type == 'default') {
				return this.userInfo;
			} else if (systemInfo().token) {
				try {
					let info = await mergeReq({ port: port.MEMBER_GETMEMBERDETAIL });
					this.userInfo = info;
					uni.setStorageSync(USER_INFO, info);
					return info;
				} catch (error) {
					this.userInfo = {};
					return {};
				}
			} else {
				this.userInfo = {};
				return {};
			}
		},

		async fetchConfig(type: string = 'default'): Promise<any> {
			if (Object.keys(this.config).length > 0 && type == 'default') {
				return this.config;
			} else {
				try {
					let config = await mergeReq({ port: port.SYSTEM_GETSET });
					this.config = config;
					return config;
				} catch (error) {
					return this.config;
				}
			}
		},
	},
});
