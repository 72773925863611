<script lang="ts" setup>
import { watch } from 'vue';
import systemInfo from '@/stores/systemInfo';
import { onLaunch } from '@dcloudio/uni-app';
import { SYSTEM_INFO } from '@config/constant';
import projectData from '@/stores/projectData';
import { checkUpdated, toLogin } from '@utils/methods';

const sysInfo = systemInfo(),
	prjData = projectData();

sysInfo.$subscribe((mutation, state) =>
	uni.setStorage({
		key: SYSTEM_INFO,
		data: state,
	})
);

onLaunch(({ path, query }: any) => {
	checkUpdated();
	sysInfo.setSysData(query);
	sysInfo.token == '' && toLogin();

	watch(
		() => sysInfo.token,
		() => prjData.fetchUserInfo('init'),
		{ immediate: true }
	);
});
</script>

<style lang="scss">
@import '@climblee/uv-ui/index.scss';
@import '~@/assets/styles/public.scss';
</style>
