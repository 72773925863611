enum port {
	// Agreement
	AGREEMENT_GETAGREEMENTDETAIL = 'Agreement.GetAgreementDetail',

	// Sms
	SMS_SENDSMS = 'Sms.SendSms',

	// Home
	HOME_GETNAVICONLIST = 'Home.GetNaviconList',

	// System
	SYSTEM_GETSET = 'System.GetSet',
	SYSTEM_UPLOAD = 'System.Upload',
	SYSTEM_GETSETDATA = 'System.GetSetData',

	// Pay
	PAY_GETPAYLIST = 'Pay.GetPayList',
	PAY_PAYORDER = 'Pay.PayOrder',
	PAY_CHECKSTATUS = 'Pay.CheckStatus',

	// Wechat
	WECHAT_CODETOPENID = 'Wechat.CodeToOpenid',

	// Nav
	NAV_GETNAVLIST = 'Nav.GetNavList',

	// Member
	MEMBER_LOGIN = 'Member.Login',
	MEMBER_CHANGEPWD = 'Member.ChangePwd',
	MEMBER_GETMEMBERDETAIL = 'Member.GetMemberDetail',
	MEMBER_LOGOUT = 'Member.Logout',

	// Wares
	WARES_GETWARESLIST = 'Wares.GetWaresList',

	// Code
	CODE_DELETEBOX = 'Code.DeleteBox',
	CODE_CHECKCODE = 'Code.CheckCode',
	CODE_POSTBOX = 'Code.PostBox',
	CODE_CHECKBOX = 'Code.CheckBox',
	CODE_REPLACEBOX = 'Code.ReplaceBox',
	CODE_CHECKBOXSEND = 'Code.CheckBoxSend',

	// Agent
	AGENT_GETSENDLIST = 'Agent.GetSendList',
	AGENT_GETSENDDETAIL = 'Agent.GetSendDetail',
	AGENT_POSTEXPRESS = 'Agent.PostExpress',

	// Express
	EXPRESS_GETEXPRESSLIST = 'Express.GetExpressList',

	// Version
	VERSION_GETVERSION = 'Version.GetVersion',
}

export default port;
