import { defineStore } from 'pinia';
import { API_BASE } from '@config/api';
import { getPlatform } from '@utils/methods';
import { SYSTEM_INFO } from '@config/constant';
import { assignObj, deepClone } from '@utils/methods';

declare var UNI_ENV: string;

export default defineStore('systemInfo', {
	state: () => ({
		env: '', // 环境
		sh: 0, // 状态栏高度
		bh: 0, // 底部安全区高度
		nh: 0, // 导航栏高度
		th: 0, // tabbar高度
		token: '', // 用户token
		plat: getPlatform(), // 运行平台
		menuData: {
			top: '',
			left: '',
			right: '',
			width: '',
			height: '',
			bottom: '',
			rightM: '',
		}, // 小程序菜单信息
	}),

	getters: {
		api(): any {
			// let apiBase = API_BASE.prod;
			let apiBase = API_BASE[this.env];

			return {
				API_ROOT: apiBase.api,
				WAP_ROOT: apiBase.wap,
				DOWNLOAD: API_BASE.DOWNLOAD,
				SIGN_KEY: API_BASE.SIGN_KEY,
			};
		},
		showSwitch() {
			switch (this.plat) {
				case 'ios':
				case 'android':
					let version = plus.runtime.version;
					return version?.indexOf('test') != -1;
				case 'mp':
					return UNI_ENV == 'dev';
				default:
					return false;
			}
		},
	},

	actions: {
		mpInit() {
			// #ifdef MP
			let menuData: any = uni.getMenuButtonBoundingClientRect();
			let { screenWidth } = uni.getSystemInfoSync();
			menuData.rightM = screenWidth - menuData.left;
			this.menuData = menuData;
			// #endif
			// #ifndef H5
			let { top = 0, bottom = 0 } = uni.getSystemInfoSync().safeAreaInsets as any;
			this.sh = top;
			this.bh = bottom;
			// #endif
		},
		setSysData(query: any) {
			let isDev = false,
				plat = this.plat,
				state = deepClone(this.$state),
				isApp = ['android', 'ios'].includes(plat),
				_sysInfo = uni.getStorageSync(SYSTEM_INFO);

			// #ifdef APP-PLUS
			let version = plus.runtime.version;
			isDev = version?.indexOf('test') != -1;
			// #endif

			_sysInfo && (state = assignObj(state, _sysInfo));
			state = assignObj(state, query);

			if (UNI_ENV == 'prod') {
				state.env = 'prod';
			} else if (isApp) {
				state.env = isDev ? 'dev' : 'prod';
			} else {
				let env = UNI_ENV || process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
				state.env = env;
			}

			this.$patch(state);
			this.mpInit();
		},
	},
});
