<template>
	<view class="common-navbar">
		<view class="navbar">
			<view class="status-box"></view>
			<view class="default">
				<view class="btn-box left" @tap="showBack && $navigateTo(-1)">
					<uv-icon name="arrow-left" color="back" size="36rpx" v-if="showBack"></uv-icon>
				</view>
				<view class="page-title">
					{{ title }}
				</view>
				<view class="btn-box">
					<slot></slot>
				</view>
			</view>
		</view>
	</view>
</template>
<script lang="ts" setup>
import systemInfo from '@/stores/systemInfo';
import { onMounted, getCurrentInstance } from 'vue';

const sysInfo = systemInfo(),
	instance = getCurrentInstance();

defineProps({
	showBack: {
		default: true,
		type: Boolean,
	},
	bgColor: {
		default: '#fff',
		type: String,
	},
	title: {
		default: '',
		type: String,
	},
});

onMounted(() => {
	uni.createSelectorQuery()
		.in(instance)
		.select('.common-navbar')
		.boundingClientRect(({ height }: any) => (sysInfo.nh = height))
		.exec();
});
</script>

<style lang="scss" scoped>
.common-navbar {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	background-color: #fff;
	.navbar {
		width: 100%;
		.status-box {
			height: var(--status-bar-height);
		}
		.default {
			height: 92rpx;
			@include row-flex;
			.btn-box {
				height: 100%;
				width: 150rpx;
				@include center;
				&.left {
					justify-content: flex-start;
					padding-left: 35rpx;
				}
			}
			.page-title {
				text-align: center;
				font-weight: bold;
				flex: 1;
				font-size: 30rpx;
			}
		}
	}
}
</style>
