// tabbar
export const tabbar: string[] = [];

// 原生
export const native: string[] = [
	...tabbar,
	'/pages/home',
	'/pages/account/pwd',
	'/pages/account/login',
	'/pages/deliver/list',
	'/pages/deliver/detail',
	'/pages/box/pack',
	'/pages/box/unpack',
	'/pages/box/replace',
];

// 不需要登录
export const noLogin: string[] = ['/pages/account/login', '/pages/account/pwd'];
